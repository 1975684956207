<template>
  <div :class="wrapperClasses">
    <h1 class="uppercase text-center font-medium lg:text-heading leading-none lg:py-13 py-8 tracking-mid text-mid">{{ $t('Browse by Brand') }}</h1>
    <div class="browse-brand mx-auto flex flex-between flex-col md:flex-row max-w-xl justify-center">
      <div class="w-full md:w-1/3 md:mr-lg mb-lg border-solid browse-brand__item border-20" v-for="(item, i) in item.items.slice(0, 3)" :key="i">
        <component v-bind="linkProps(item.link)" class="block w-full relative h-58 flex items-center justify-center w-full bg-cover bg-center bg-no-repeat" :style="`background-image: url(${item.bg_image})`">
          <img :src="item.logo">
        </component>
        <div class="p-7.5">
          <p class="text-center" v-html="item.short_description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import StoryblokItem from 'theme/mixins/blocks/StoryblokItem'

export default {
  extends: Blok,
  mixins: [StoryblokItem],
  name: 'BrowseByBrand'
}
</script>

<style lang="scss" scoped>
  .browse-brand {
    &__item {
      border-image: url("/assets/pattern-tile.png") 20;
    }
  }
</style>
